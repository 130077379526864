import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {AppRouterService} from '@core/services';
import {isUserRole, UserRoleType} from '@models/directories';
import {AuthService} from '@services/auth/auth.service';

import {RolesService} from '../../services/roles/roles.service';

@Injectable()
export class RolesGuard  {

  constructor(
    private readonly appRouterService: AppRouterService,
    private readonly rolesService: RolesService,
    private readonly authService: AuthService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canView(next.data.roles);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    const roles = route.data && route.data.roles;
    if (!roles) {
      return true;
    }
    return this.canView(roles);
  }

  private canView(roles: UserRoleType | UserRoleType[]): Observable<boolean> {
    return this.rolesService.userRole$
      .pipe(
        switchMap(userRole => {
          if (!userRole || !isUserRole(userRole)) {
            this.authService.logoutUser();
            return of(false);
          }
          const hasRoles = this.rolesService.hasRole(roles, userRole);
          if (!hasRoles) {
            this.appRouterService.navigateToStartPageByRole();
            return of(false);
          }
          return of(true);
        }),
      );
  }

}
