import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {AppStateService} from '@core/modules';

import {RolesDirective} from './directives/roles/roles.directive';
import {RolesGuard} from './guards/roles/roles.guard';
import {RolesService} from './services/roles/roles.service';

const rolesServiceFactory = (
  appStateService: AppStateService,
): RolesService => new RolesService(appStateService);

@NgModule({
  declarations: [RolesDirective],
  imports: [
    CommonModule,
  ],
  providers: [
    RolesGuard,
    RolesService,
  ],
  exports: [
    RolesDirective,
  ],
})
export class RolesModule {
  static forRoot(): ModuleWithProviders<RolesModule> {
    return {
      ngModule: RolesModule,
      providers: [
        {
          provide: RolesService,
          useFactory: rolesServiceFactory,
          deps: [AppStateService],
        },
        RolesGuard,
      ],
    };
  }
}
