import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {takeUntil} from 'rxjs/operators';

import {DestroySubscription} from '@helpers/destroy-subscription';
import {UserRoleType} from '@models/directories';

import {RolesService} from '../../services/roles/roles.service';

@Directive({
  selector: '[appRoles]',
})
export class RolesDirective extends DestroySubscription {

  private viewRef: EmbeddedViewRef<any> | null = null;
  private readonly templateRef: TemplateRef<any>;

  @Input() set appRoles(roles: UserRoleType[] | UserRoleType) {
    this.init(roles);
  }

  constructor(
    private readonly ref: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly rolesService: RolesService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.templateRef = this.ref;
  }

  private init(roles: UserRoleType[] | UserRoleType): void {
    this.rolesService.userRole$
      .pipe(
        takeUntil(this.destroyStream$),
      ).subscribe(userRole => {
        const hasRole = userRole ? this.rolesService.hasRole(roles, userRole) : false;
        if (!hasRole) {
          this.clearView();
          return;
        }
        this.createView();
      });
  }
  private createView(): void {
    if (!this.viewRef) {
      this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.cdr.detectChanges();
    }
  }

  private clearView(): void {
    this.viewContainerRef.clear();
    this.viewRef = null;
  }
}
